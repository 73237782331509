import { origin } from "../../lib/origin"
import { Loading } from "../loading"

const redirectUri = `${origin}/verify/tos-redirect`

export const AcceptTerms = ({ tosLink }: { tosLink: string }) => {
  if (!tosLink) {
    return <Loading />
  }

  return (
    <iframe
      title="TOS link"
      src={`${tosLink}&redirect_uri=${redirectUri}`}
      width="100%"
      height="350px"
    />
  )
}
