import { useEffect } from "react"

import { Loading } from "../loading"

const BridgeTermsOfServiceRedirect = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const signedAgreementId = params.get("signed_agreement_id")

    window.parent.postMessage(
      {
        type: "button-click",
        signedAgreementId,
      },
      "*",
    )
  }, [])

  return <Loading />
}

export { BridgeTermsOfServiceRedirect }
