import { Link } from "@chakra-ui/react"
import React from "react"

interface TextLinkProps {
  href: string
  text: string
}

const TextLink = ({ href, text }: TextLinkProps) => {
  return (
    <Link
      isExternal
      href={href}
      fontWeight={500}
      color="earth.700"
      textDecoration="underline"
    >
      {text}
    </Link>
  )
}

export { TextLink }
