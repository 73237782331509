import { Box, Center, Text } from "@chakra-ui/react"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"
import { useEffect } from "react"

import { Header } from "../../components/header/header"
import { OfframpWidget } from "../../components/offramp/offramp-widget"
import { amplitude } from "../../packages/amplitude"

const Offramp = () => {
  const { safe } = useSafeAppsSDK()

  useEffect(() => {
    amplitude.track("visit_offramp", {
      safe_address: safe.safeAddress,
    })

    if (!localStorage.getItem("offramp_visited")) {
      amplitude.track("finish_kyb", {
        safe_address: safe.safeAddress,
      })
      localStorage.setItem("offramp_visited", "true")
    }
  }, [safe.safeAddress])

  return (
    <Box background="stone.50" height="100vh">
      <Header />
      <Center>
        <Box p="40px" w="628px" h="100%">
          {/* TEXT */}
          <Box mb={8}>
            <Text mb={4} fontSize="3xl" lineHeight="32px" fontWeight={600}>
              Offramp USDC/USDT
            </Text>
            <Text color="stone.500">
              You can send any amount of USD to a bank accepting ACH payments using USDC
              or USDT from your Safe.
            </Text>
          </Box>

          <OfframpWidget />
        </Box>
      </Center>
    </Box>
  )
}

export default Offramp
