export const isDevelopmentDeployment =
  import.meta.env.VITE_ENV_TARGET === "preview" && import.meta.env.PROD
export const isProductionDeployment =
  import.meta.env.VITE_ENV_TARGET === "production" && import.meta.env.PROD
export const isDev = import.meta.env.VITE_ENV === "development" || isDevelopmentDeployment

export const origin = isDevelopmentDeployment
  ? "https://saferamp.utopialabs.dev"
  : isProductionDeployment
  ? "https://saferamp.utopialabs.com"
  : `http://localhost:${import.meta.env.VITE_PORT}`
