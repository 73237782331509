import { Box, Center, Link, Text } from "@chakra-ui/react"

import { Header } from "../components/header/header"

export function InvalidNetworkPage() {
  return (
    <Box background="stone.50" height="100vh">
      <Header />
      <Center>
        <Box p="40px" w="628px" h="100%">
          <Box mb={8}>
            <Text mb={4} fontSize="3xl" lineHeight="32px" fontWeight={600}>
              Network not supported
            </Text>
            <Text color="stone.500">
              Safe App Bank Transfers only supported on Ethereum Mainnet. For additional
              networks, visit{" "}
              <Link fontWeight={500} color="earth.700" href="https://app.utopialabs.com">
                app.utopialabs.com
              </Link>
              .
            </Text>
          </Box>
        </Box>
      </Center>
    </Box>
  )
}
