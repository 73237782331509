import type { AxiosResponse } from "axios"

import type { Account } from "@utopia/safe-ramp-utils"

import { axios } from "../clients/axios"

export const getAccount = async (): Promise<Account> => {
  const response: AxiosResponse<Account> = await axios.get(`/api/account`)
  return response.data
}

export const deleteAccount = async (): Promise<Account> => {
  const response: AxiosResponse<Account> = await axios.delete(`/api/account`)
  return response.data
}
