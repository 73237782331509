import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from "@chakra-ui/react"

interface CustomSelectProps<T extends CustomSelectItem> {
  items: T[]
  selectedOption: T | null
  setSelectedOption: (option: T) => void
}

export type CustomSelectItem = {
  symbol: string
  logo: string
  tokenAddress: string
}

const CurrencySelector = <T extends CustomSelectItem>({
  items,
  selectedOption,
  setSelectedOption,
}: CustomSelectProps<T>) => {
  return (
    <Menu colorScheme="blue" matchWidth>
      <MenuButton
        width="100%"
        as={Box}
        backgroundColor="white"
        borderColor="stone.200"
        borderLeft="none"
        placeholder="Enter Bank Account"
        height={9}
        color="stone.700"
        borderWidth={1}
        roundedRight="md"
        cursor="pointer"
        px={4}
        _hover={{
          backgroundColor: "stone.50",
        }}
      >
        {selectedOption ? (
          <HStack spacing={1} flex="0 1 35%" h="40px">
            <Image src="images/icons/usflag.svg" alt="US Flag" aria-hidden w={3} h={3} />
            <Text color="stone.900" _dark={{ color: "stone.200" }}>
              USD
            </Text>
            <Text color="stone.500">with</Text>
            <Image
              src={selectedOption?.logo}
              alt={`${selectedOption?.symbol} Logo`}
              aria-hidden
              w={3}
              h={3}
            />
            <Text color="stone.900" _dark={{ color: "stone.200" }}>
              {selectedOption?.symbol}
            </Text>
            <Spacer />
            <ChevronDownIcon />
          </HStack>
        ) : (
          <Flex h="full" w="full" alignItems="center" justifyContent="space-between">
            <Text display="flex" alignItems="center" gap={2}>
              Select currency conversion
            </Text>
            <ChevronDownIcon />
          </Flex>
        )}
      </MenuButton>
      <MenuList>
        {items.map((item) => (
          <MenuItem
            key={item.symbol}
            onClick={() => {
              setSelectedOption(item)
            }}
          >
            <HStack spacing={1} flex="0 1 35%" h="20px">
              <Image
                src="images/icons/usflag.svg"
                alt="US Flag"
                aria-hidden
                w={3}
                h={3}
              />
              <Text color="stone.900" _dark={{ color: "stone.200" }}>
                USD
              </Text>
              <Text color="stone.500">with</Text>
              <Image
                src={item?.logo}
                alt={`${item?.symbol} Logo`}
                aria-hidden
                w={3}
                h={3}
              />
              <Text color="stone.900" _dark={{ color: "stone.200" }}>
                {item?.symbol}
              </Text>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
export { CurrencySelector }
