import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, Flex, Image, Link, Text } from "@chakra-ui/react"
import React from "react"

const UtopiaBankTransferPreview = () => {
  return (
    <Link
      href="https://app.utopialabs.com"
      isExternal
      _hover={{ textDecoration: "none" }}
    >
      <Box
        _hover={{
          background: "sea.100",
          transition: "background 0.2s",
        }}
        p="24px 32px"
        mx="15px"
        w={{
          base: "550px",
        }}
        display="flex"
        flexDirection="column"
        alignItems="start"
        borderRadius="8px"
        border="1px solid"
        borderColor="stone.200"
        background="sea.50"
        overflow="hidden" // Hide the content that goes beyond the box
      >
        <Flex justify="space-between" w="full">
          <Box>
            <Text mb="12px" fontWeight={600} fontSize="18px" lineHeight="20px">
              Want to track your bank transfers progress and get more details around your
              transaction?
            </Text>
            <Text>
              Find out why 250+ leading crypto companies manage their Safe on Utopia
            </Text>
          </Box>

          <ExternalLinkIcon w="24px" h="24px" />
        </Flex>
        <Image
          src="/images/utopia/bank_transfers.png"
          alt="Utopia Logo"
          aria-hidden
          objectFit="cover"
          minWidth="834px"
          mt="16px"
        />
      </Box>
    </Link>
  )
}

export { UtopiaBankTransferPreview }
