export const colors = {
  stone: {
    50: "#FAFAFA",
    100: "#F4F4F5",
    200: "#E3E3E5",
    300: "#D2D2D6",
    400: "#A6A6AD",
    500: "#73737A",
    600: "#52525B",
    700: "#404047",
    800: "#27272A",
    900: "#1B1B1F",
  },
  earth: {
    50: "#F0FAF7",
    100: "#D8E5E1",
    200: "#C0D1CC",
    300: "#AABDB6",
    400: "#83A89C",
    500: "#659484",
    600: "#568072",
    700: "#4C7064",
    800: "#45665B",
    900: "#385249",
  },
  sea: {
    50: "#EBF8FF",
    100: "#D2EDFA",
    200: "#B2D8EB",
    300: "#92BFD6",
    400: "#74A8C2",
    500: "#4C8DAD",
    600: "#437C99",
    700: "#3D708A",
    800: "#36647A",
    900: "#2D4F61",
  },
  saffron: {
    50: "#FFF8EB",
    100: "#F5E7CE",
    200: "#E0CEAB",
    300: "#CCB68B",
    400: "#B89F6E",
    500: "#A38548",
    600: "#8F743F",
    700: "#806838",
    800: "#715C32",
    900: "#5C4B29",
  },
  sunset: {
    50: "#FFF2F0",
    100: "#FFE1DB",
    200: "#FACCC3",
    300: "#E5ACA1",
    400: "#D1968A",
    500: "#BD7162",
    600: "#A85F50",
    700: "#995749",
    800: "#8A4E42",
    900: "#704138",
  },
} as const

export const colorSchemesKeys = Object.keys(colors) as (keyof typeof colors)[]
