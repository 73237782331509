import type { ComponentStyleConfig } from "@chakra-ui/theme"

const getSolidDisabledStyles = (c: string) => ({
  _light: {
    backgroundColor: `${c}.300`,
    borderColor: `${c}.300`,
  },
  _dark: {
    backgroundColor: `${c}.500`,
    borderColor: `${c}.500`,
  },
})

const getOutlineDisabledStyles = (c: string) => ({
  _light: {
    color: `${c}.300`,
    borderColor: `${c}.300`,
  },
  _dark: {
    color: `${c}.500`,
    borderColor: `${c}.500`,
  },
})

export const buttonTheme: ComponentStyleConfig = {
  defaultProps: {
    variant: "solid",
    colorScheme: "earth",
    size: "md",
  },
  baseStyle: {
    fontFamily: "body",
    fontWeight: "medium",
    _focusVisible: {
      boxShadow: "none",
      outline: "solid",
    },
  },
  variants: {
    solid: (props) => ({
      _light: {
        backgroundColor: `${props.colorScheme}.700`,
        borderColor: `${props.colorScheme}.700`,
        color: `white`,
        _disabled: getSolidDisabledStyles(props.colorScheme)._light,
      },
      _dark: {
        backgroundColor: `${props.colorScheme}.300`,
        borderColor: `${props.colorScheme}.300`,
        color: `stone.900`,
        _disabled: getSolidDisabledStyles(props.colorScheme)._dark,
      },
      _hover: {
        _light: {
          backgroundColor: `${props.colorScheme}.800`,
          borderColor: `${props.colorScheme}.800`,
          _disabled: getSolidDisabledStyles(props.colorScheme)._light,
        },
        _dark: {
          backgroundColor: `${props.colorScheme}.200`,
          borderColor: `${props.colorScheme}.200`,
          _disabled: getSolidDisabledStyles(props.colorScheme)._dark,
        },
      },
      _focusVisible: {
        outline: "solid",
        outlineColor: `${props.colorScheme}.500`,
      },
    }),
    outline: (props) => ({
      border: "1px solid",
      _light: {
        color: `${props.colorScheme}.700`,
        borderColor: `${props.colorScheme}.700`,
        _disabled: getOutlineDisabledStyles(props.colorScheme)._light,
      },
      _dark: {
        color: `${props.colorScheme}.300`,
        borderColor: `${props.colorScheme}.300`,
        _disabled: getOutlineDisabledStyles(props.colorScheme)._dark,
      },
      _hover: {
        _light: {
          color: `${props.colorScheme}.800`,
          borderColor: `${props.colorScheme}.800`,
          _disabled: getOutlineDisabledStyles(props.colorScheme)._light,
        },
        _dark: {
          color: `${props.colorScheme}.200`,
          borderColor: `${props.colorScheme}.200`,
          _disabled: getOutlineDisabledStyles(props.colorScheme)._dark,
        },
      },
    }),
    link: (props) => ({
      fontWeight: "semibold",
      _light: {
        color: `${props.colorScheme}.700`,
      },
      _dark: {
        color: `${props.colorScheme}.300`,
      },
    }),
    clear: {
      backgroundColor: "transparent",
    },
  },
  sizes: {
    sm: { borderRadius: "base" },
    md: { borderRadius: "base" },
    lg: { fontSize: "md", borderRadius: "lg", fontWeight: "semibold" },
  },
}
