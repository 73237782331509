import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import React, { useMemo } from "react"
import useSWR from "swr"

import type { Account, User } from "@utopia/safe-ramp-utils"

import { getUser } from "~/api/user"

import { getAccount } from "../api/account"
import { AppContext } from "../clients/context"

export const AppProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { handleLogOut } = useDynamicContext()
  const { data: user, isLoading: isUserLoading } = useSWR<User, Error>("/user", getUser, {
    shouldRetryOnError: false,
  })
  const { data: account, isLoading: isAccountLoading } = useSWR<Account, Error>(
    "/account",
    getAccount,
    {
      refreshInterval: 1000 * 60 * 2, // every 2 mins
      shouldRetryOnError: false,
      onError: () => {
        if (user) {
          void handleLogOut()
        }
      },
    },
  )

  const isLoading = useMemo(
    () => isAccountLoading || isUserLoading,
    [isAccountLoading, isUserLoading],
  )

  return (
    <AppContext.Provider value={{ account, user, isLoading }}>
      {children}
    </AppContext.Provider>
  )
}
