import type { AxiosResponse } from "axios"

import type { User } from "@utopia/safe-ramp-utils"

import { axios } from "~/clients/axios"

export const getUser = async (): Promise<User> => {
  const response: AxiosResponse<User> = await axios.get(`/api/user`)
  return response.data
}
