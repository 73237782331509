import { Flex, Text } from "@chakra-ui/react"
import React from "react"

import { HeaderComponents } from "./header-layout"

export const Header = () => {
  return (
    <HeaderComponents>
      <Flex alignItems="center">
        <Text color="stone.500">
          |
          <Text ml={1} fontSize="sm" as="span">
            USDC/USDT bank transfers by Utopia Labs
          </Text>
        </Text>
      </Flex>
    </HeaderComponents>
  )
}
