import { inputAnatomy as parts } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/styled-system"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  ...parts.keys,
])

const fieldBaseStyle = defineStyle({
  _disabled: {
    cursor: "not-allowed",
  },
})
const elementBaseStyle = defineStyle({
  border: 0,
  boxShadow: "none",
  gap: 4,
  outline: 0,
})
const addonBaseStyle = defineStyle({
  border: 0,
  boxShadow: "none",
  fontFeatureSettings: "tnum lnum",
  gap: 4,
  lineHeight: "md",
  outline: 0,
  textAlign: "left",
})

const filledVariant = definePartsStyle(({ colorScheme }) => {
  return {
    field: {
      borderWidth: "1px",
      outline: 0,

      _light: {
        boxShadow: "1",
        bg: `${colorScheme}.50`,
        borderColor: `white`,
        color: `${colorScheme}.900`,
      },

      _dark: {
        boxShadow: "1-inverted",
        bg: `${colorScheme}.900`,
        borderColor: `${colorScheme}.900`,
        color: `${colorScheme}.50`,
      },

      _hover: {
        _light: {
          bg: `${colorScheme}.50`,
        },
        _dark: {
          bg: `${colorScheme}.800`,
        },
        _disabled: {
          _light: {
            bg: `white`,
          },
          _dark: {
            bg: `${colorScheme}.900`,
          },
        },
      },

      _placeholder: {
        _light: {
          color: `${colorScheme}.500`,
        },
      },
      _focus: {
        _light: {
          boxShadow: "2",
        },
        _dark: {
          boxShadow: "2-inverted",
        },
      },
      _invalid: {
        _light: {
          borderColor: `sunset.700`,
        },
        _dark: {
          borderColor: `sunset.400`,
        },
      },
    },
  }
})

const outlinedVariant = definePartsStyle(({ colorScheme }) => {
  return {
    field: {
      borderWidth: "1px",
      outline: 0,
      borderRadius: 8,

      _light: {
        boxShadow: "1",
        bg: `white`,
        color: `${colorScheme}.900`,
      },

      _hover: {
        _light: {
          bg: `${colorScheme}.50`,
        },
        _disabled: {
          _light: {
            bg: `white`,
          },
        },
      },

      _placeholder: {
        _light: {
          color: `${colorScheme}.500`,
        },
      },
      _focus: {
        _light: {
          boxShadow: "2",
        },
        _dark: {
          boxShadow: "2-inverted",
        },
      },
      _focusWithin: {
        _light: {
          boxShadow: "2",
        },
      },
      _invalid: {
        _light: {
          borderColor: `sunset.700`,
        },
      },
    },
  }
})

const searchVariant = definePartsStyle(({ colorScheme }) => {
  return {
    field: {
      borderWidth: "1px",
      outline: 0,
      borderRadius: 4,
      height: 9,

      _hover: {
        _light: {
          bg: `${colorScheme}.50`,
        },
        _disabled: {
          _light: {
            bg: `white`,
          },
        },
      },
      _placeholder: {},
      _focus: {
        _light: {
          boxShadow: "2",
        },
      },
      _focusWithin: {
        _light: {
          boxShadow: "2",
        },
      },
      _invalid: {
        _light: {
          borderColor: `sunset.700`,
        },
      },
    },
  }
})

const restVariant = definePartsStyle(({ colorScheme }) => {
  return {
    field: {
      borderWidth: "1px",
      outline: 0,
      borderRadius: 8,
      _light: {
        bg: `white`,
        borderColor: `white`,
        color: `${colorScheme}.900`,
      },
      _dark: {
        bg: `${colorScheme}.900`,
        borderColor: `${colorScheme}.900`,
        color: `${colorScheme}.50`,
      },

      _hover: {
        _light: {
          bg: `${colorScheme}.50`,
        },
        _dark: {
          bg: `${colorScheme}.800`,
        },
        _disabled: {
          _light: {
            bg: `white`,
          },
          _dark: {
            bg: `${colorScheme}.900`,
          },
        },
      },
      _placeholder: {
        _light: {
          color: `${colorScheme}.500`,
        },
        _dark: {
          color: `${colorScheme}.400`,
        },
      },
      _focus: {
        _light: {
          boxShadow: "2",
        },
        _dark: {
          boxShadow: "2-inverted",
        },
      },
      _focusWithin: {
        _light: {
          boxShadow: "2",
        },
        _dark: {
          boxShadow: "2-inverted",
        },
      },
      _invalid: {
        _light: {
          borderColor: `sunset.700`,
        },
        _dark: {
          borderColor: `sunset.400`,
        },
      },
    },
  }
})

const variants = {
  filled: filledVariant,
  outlined: outlinedVariant,
  search: searchVariant,
  rest: restVariant,
  unstyled: {},
}

export const inputTheme = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: "stone",
    variant: "rest",
  },
  variants,
  sizes: {
    md: definePartsStyle({
      field: {
        height: 9,
      },
    }),
  },
  baseStyle: definePartsStyle({
    field: fieldBaseStyle,
    element: elementBaseStyle,
    addon: addonBaseStyle,
  }),
})
