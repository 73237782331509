import { Box, Center, Link, Text } from "@chakra-ui/react"

import { Header } from "../components/header/header"
import { TextLink } from "../components/ui/text-link"

export default function ErrorPage({ message }: { message?: string }) {
  return (
    <Box background="stone.50" height="100vh">
      <Header />
      <Center>
        <Box p="40px" w="628px" h="100%">
          <Box mb={8}>
            <Text mb={4} fontSize="3xl" lineHeight="32px" fontWeight={600}>
              Error
            </Text>
            {message ? (
              <>
                <Text color="stone.500">{message}</Text>
                <Text mt={5} color="stone.500">
                  Please <TextLink href="/" text="click here" key={1} /> to navigate back
                  to the home page.
                </Text>
              </>
            ) : (
              <Text color="stone.500">
                There was an error with loading this page. Please click{" "}
                <Link fontWeight={500} color="earth.700" href="/">
                  here
                </Link>{" "}
                to navigate back to the home page.
              </Text>
            )}
          </Box>
        </Box>
      </Center>
    </Box>
  )
}
