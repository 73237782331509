import { Box, Button, Center, Link, Text, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

import { UtopiaBankTransferPreview } from "~/components/offramp/utopia-bank-transfer-flag"

import { Header } from "../../components/header/header"

const OfframpConfirm = () => {
  const navigate = useNavigate()

  return (
    <Box background="stone.50" height="100vh">
      <Header />
      <Center flexDirection="column">
        <Box p="40px" w="628px" h="100%">
          {/* TEXT */}
          <Box mb={3}>
            <Text mb={4} fontSize="3xl" lineHeight="32px" fontWeight={600}>
              You successfully queued your bank transfer.
            </Text>
            <Text color="stone.500">
              Now, you just need to execute your transaction in your Safe. Bank transfers
              typically take 1-3 business days to complete once executed.
            </Text>
          </Box>

          <VStack width="100%">
            <UtopiaBankTransferPreview />

            <Button
              mt={3}
              onClick={() => {
                navigate("/offramp")
              }}
              width="100%"
            >
              Queue another
            </Button>

            <Link width="100%" isExternal href="https://app.utopialabs.com">
              <Button variant="outline" width="100%">
                Track bank transfer in Utopia
              </Button>
            </Link>
          </VStack>
        </Box>
      </Center>
    </Box>
  )
}

export default OfframpConfirm
