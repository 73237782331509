import type { AxiosResponse } from "axios"

import type {
  CompleteTransferInput,
  CreateTransferInput,
  OffRampTransfer,
  Transfer,
} from "@utopia/safe-ramp-utils"

import { axios } from "../clients/axios"

export const createTransfer = async (data: CreateTransferInput): Promise<Transfer> => {
  const response: AxiosResponse<Transfer> = await axios.post(`/api/transfer`, data, {
    validateStatus: (status) => status < 400,
  })
  return response.data
}

export const completeTransfer = async (
  data: CompleteTransferInput,
): Promise<OffRampTransfer> => {
  const response: AxiosResponse<OffRampTransfer> = await axios.put(
    `/api/transfer`,
    data,
    {
      validateStatus: (status) => status < 400,
    },
  )
  return response.data
}
