import type { AxiosResponse } from "axios"

import { axios } from "../clients/axios"

export interface User {
  address: string
  safeAddress: string
  network: number
  safeId?: string
  expiry?: number
}

export interface LoginResponse extends AxiosResponse {
  data: User
}

interface LoginHeaders extends Record<string, string | number | boolean> {
  authToken: string
}
interface LoginParams {
  safeAddress: string
  address: string
  network: number
}

export const loginToSafe = (
  params: LoginParams,
  { authToken, ...headers }: LoginHeaders,
) => {
  return axios.post<LoginParams, LoginResponse>(`/api/auth/login`, params, {
    headers: { ...headers, Authorization: `Bearer ${authToken}` },
    validateStatus(status) {
      return status < 400 // Resolve only if the status code is less than 500
    },
  })
}

export const logout = () => {
  return axios.delete<Record<string, never>>(`/api/auth/login`)
}
