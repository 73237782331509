import { useToast } from "@chakra-ui/react"
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum-all"
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"
import { useNavigate } from "react-router-dom"
import { mutate } from "swr"

import { evmNetworks } from "../constants"
import { loginToSafe, logout } from "../lib/login"

const lightCssOverrides = `
.dynamic-connect-button {
  background-color: #4C7064;
}
.dynamic-connect-button:hover:enabled {
  background-color: #45665B;
}
.dynamic-connect-button .typography { color: white; }
.dynamic-connect-button:hover:enabled .typography { color: white; }
.dynamic-widget-inline-controls__network-picker { display: none }
.dynamic-widget-inline-controls .account-control__container {
  border-radius: 8px; height: 36px; border: 1px solid #E3E3E5; background-color: white; color: #404047; transition: background-color 0.2s;
} .dynamic-widget-inline-controls .account-control__container:hover {
  background-color: #F4F4F5;
}`

type DynamicContextProps = Parameters<typeof DynamicContextProvider>[0]

interface DynamicProviderProps extends Omit<DynamicContextProps, "settings"> {
  settings?: Omit<DynamicContextProps["settings"], "environmentId">
}

export const DynamicProvider = ({ children }: DynamicProviderProps) => {
  const navigate = useNavigate()
  const { safe } = useSafeAppsSDK()
  const toast = useToast()

  return (
    <DynamicContextProvider
      settings={{
        appName: "Utopia USDC Bank Transfers",
        environmentId:
          import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID ||
          "40d58b12-a413-4b62-b65c-38e8c7e0051e",
        evmNetworks,
        cssOverrides: lightCssOverrides,
        multiWallet: false,
        walletConnectors: [EthereumWalletConnectors],
        displayTermsOfService: true,
        termsOfServiceUrl:
          "https://uploads-ssl.webflow.com/63d9506ce2c2a2c301338d9b/63efe7e93ae176671adc96f5_62cf192e529adc5a08789369_UtopiaLabs_Terms_of_Service%20-%20Cooley%20Edits%20(07-06-22).pdf",
        privacyPolicyUrl:
          "https://uploads-ssl.webflow.com/63d9506ce2c2a2c301338d9b/63efe7ba1a4b7a45073da58d_620ecd3375c1da45b9f4b81b_Privacy%20Policy.pdf",
        eventsCallbacks: {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAuthSuccess: async ({ handleLogOut, authToken, primaryWallet }) => {
            try {
              if (!primaryWallet?.address) {
                throw new Error("User wallet address not found")
              }
              await loginToSafe(
                {
                  address: primaryWallet.address,
                  safeAddress: safe.safeAddress,
                  network: safe.chainId,
                },
                {
                  authToken,
                },
              )
              await Promise.all([mutate("/account"), mutate("/user")])
            } catch (e) {
              toast({
                status: "error",
                title: "Failed to login. ",
                description:
                  "If you are a signer of this Safe, please contact Utopia for assistance",
              })
              console.error(e)
              void handleLogOut()
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onLogout: async () => {
            await logout()
            await mutate("/user", null)
            await mutate("/account", null)
            navigate("/login")
          },
        },
      }}
    >
      {children}
    </DynamicContextProvider>
  )
}
