import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"
import { Field, Form, Formik } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSWRConfig } from "swr"

import { onboardOrganization } from "../api/onboard"
import { AcceptTerms } from "../components/bridge/accept-terms"
import { Header } from "../components/header/header"
import { Loading } from "../components/loading"
import { SafeModal } from "../components/modal/modal"
import { useAccount } from "../hooks/use-account"
import { amplitude } from "../packages/amplitude"

const SafeApp = () => {
  const { mutate } = useSWRConfig()
  const { safe } = useSafeAppsSDK()
  const { account } = useAccount()
  const tosLink = useMemo(
    () => account?.offRampUser?.kybStatus?.bridgeKYBStatus.tosLink,
    [account?.offRampUser?.kybStatus?.bridgeKYBStatus.tosLink],
  )
  const [isAwaitingRedirect, setAwaitingRedirect] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  // show TOS modal as soon as it is available
  useEffect(() => {
    if (tosLink) {
      onOpen()
    }
  }, [onOpen, tosLink])

  // poll every 2s after TOS has been accepted
  useEffect(() => {
    if (isAwaitingRedirect) {
      const intervalId = setInterval(() => {
        void mutate("/account")
        return () => clearInterval(intervalId)
      }, 2_000)
    }
  }, [isAwaitingRedirect, mutate])

  const handleSubmit = useCallback(
    async (values: { name: string; email: string }) => {
      // initialize kyb and mutate `/account` to refetch
      try {
        await onboardOrganization(values)
      } catch (e) {
        const error = e as { response?: { status: number } }
        amplitude.track("begin_kyb_failed", {
          safe_address: safe.safeAddress,
        })

        if (error?.response?.status === 409) {
          toast({
            status: "error",
            title: "Duplicate email.",
            description:
              "Please try another email address. If you would like to use an already verified business, please contact Utopia.",
          })
        } else {
          toast({
            status: "error",
            title: "Failed to initiate KYB sequence.",
            description: "Please refresh the page and try again.",
          })
        }
      }
      await mutate("/account") // should have tosLink defined and display tos modal

      amplitude.track("begin_kyb", {
        safe_address: safe.safeAddress,
      })

      amplitude.track("begin_tos", {
        safe_address: safe.safeAddress,
      })
    },
    [mutate, safe.safeAddress, toast],
  )

  const validateName = (value: string) => {
    let error
    if (!value) {
      error = "Name is required"
    }
    return error
  }

  const validateEmail = (value: string) => {
    let error

    if (!value) {
      error = "Email is required"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address"
    }

    return error
  }

  // close modal after TOS is accepted
  useEffect(() => {
    const handler = async (
      ev: MessageEvent<{ type: string; signedAgreementId: string }>,
    ) => {
      if (
        typeof ev.data !== "object" ||
        !ev.data.type ||
        ev.data.type !== "button-click" ||
        !ev.data.signedAgreementId
      ) {
        return
      }

      amplitude.track("tos_success", {
        safe_address: safe.safeAddress,
      })

      onClose()
      // we should get routed to /verify after refetch
      setAwaitingRedirect(true)
      void mutate("/account")
    }

    window.addEventListener("message", handler)

    return () => window.removeEventListener("message", handler)
  }, [mutate, onClose, safe.safeAddress])

  if (isAwaitingRedirect) {
    return <Loading />
  }

  return (
    <Box background="stone.50" height="100vh">
      <SafeModal
        isShown={isOpen}
        onClose={onClose}
        isCloseable={false}
        modalContent={<AcceptTerms tosLink={tosLink!} />}
      />
      <Header />
      <Center>
        <Box p="40px" w="628px" h="100%">
          {/* TEXT */}
          <Box mb={8}>
            <Text mb={4} fontSize="3xl" lineHeight="32px" fontWeight={600}>
              Verify your business to send USDC/USDT bank transfers
            </Text>
            <Text color="stone.500">
              All legal entities—except those located in Arkansas, Florida, Louisiana, New
              York, as well as Belarus, Cuba, Iran, North Korea, Russia, and Syria—can be
              verified.
            </Text>
          </Box>
          {/* FORM */}
          <Formik
            initialValues={{
              name: account?.offRampUser?.kybStatus?.bridgeKYBStatus.name ?? "",
              email: account?.offRampUser?.kybStatus?.bridgeKYBStatus.email ?? "",
            }}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                <Box my={2}>
                  <Field name="name" validate={validateName}>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl isInvalid={form.errors.name && form.touched.name}>
                        <FormLabel fontWeight="400" fontSize="sm">
                          Business name
                        </FormLabel>

                        <Input
                          isRequired
                          type="text"
                          {...field}
                          variant="outlined"
                          placeholder="Enter business name"
                        />

                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my={2}>
                  <Field name="email" validate={validateEmail}>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel fontWeight="400" fontSize="sm">
                          Business email
                        </FormLabel>
                        <Input
                          {...field}
                          isRequired
                          variant="outlined"
                          placeholder="Enter business or admin email"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Button
                  isDisabled={props.isSubmitting || !!account?.offRampUser?.kybStatus}
                  type="submit"
                  mt={2}
                  w="100%"
                  colorScheme="earth"
                >
                  Get started
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Center>
    </Box>
  )
}

export default SafeApp
