import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { OFF_RAMPS_ALLOWED_NETWORKS } from "~/constants"

import { useAccount } from "./use-account"

export const useHandleRoute = () => {
  const { handleLogOut } = useDynamicContext()
  const { safe } = useSafeAppsSDK()
  const { account, user, isLoading } = useAccount()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!OFF_RAMPS_ALLOWED_NETWORKS.includes(safe.chainId)) {
      navigate("/invalid-network")
      return
    }
    if (isLoading) return
    if (!user || !account) {
      if (pathname !== "/login") {
        console.info("Not signed in, logout")
        void handleLogOut()
      }
      return
    }
    if (safe.safeAddress.toLowerCase() !== account.address.toLowerCase()) {
      console.info("Logged into a different safe, logout")
      void handleLogOut()
      return
    }
    const offRampUser = account.offRampUser

    if (!offRampUser?.kybStatus) {
      navigate("/onboarding")
    } else if (offRampUser.customerId) {
      if (!pathname.includes("/offramp")) {
        navigate("/offramp")
      }
    } else {
      const kycStatus = offRampUser.kybStatus?.bridgeKYBStatus.kycStatus
      const tosStatus = offRampUser.kybStatus?.bridgeKYBStatus.tosStatus
      if (kycStatus === "rejected") {
        navigate("/kyb-failed")
      } else if (tosStatus !== "approved") {
        navigate("/onboarding")
      } else if (["pending", "manual_review"].includes(kycStatus ?? "")) {
        navigate("/verify")
      } else {
        throw new Error(`Unexpected KYB status: kyc-${kycStatus} tos-${tosStatus}`)
      }
    }
    // handleLogout, pathname cause infinite rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, account, navigate, safe.chainId, safe.safeAddress, user])

  return <></>
}
