import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import type { ReactNode } from "react"

interface CustomSelectProps<T extends CustomSelectItem> {
  items: T[]
  selectedOption: T | null
  setSelectedOption: (option: T) => void
  additionalItems: ReactNode
}

export type CustomSelectItem = {
  label: string
  function?: () => void
}

const CustomSelect = <T extends CustomSelectItem>({
  items,
  selectedOption,
  setSelectedOption,
  additionalItems,
}: CustomSelectProps<T>) => {
  return (
    <Menu colorScheme="blue" matchWidth>
      <MenuButton
        width="100%"
        as={Box}
        backgroundColor="white"
        borderColor="stone.200"
        placeholder="Enter Bank Account"
        height={9}
        color="stone.700"
        borderWidth={1}
        rounded="md"
        cursor="pointer"
        px={4}
        _hover={{
          backgroundColor: "stone.50",
        }}
      >
        <Flex h="full" w="full" alignItems="center" justifyContent="space-between">
          <Text display="flex" alignItems="center" gap={2}>
            {selectedOption ? selectedOption.label : "Add or select a bank account"}
          </Text>
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList>
        {items
          .filter((option) => option.label !== selectedOption?.label)
          .map((item) => (
            <MenuItem
              key={item.label}
              onClick={() => {
                if (item.function) {
                  item.function()
                  return
                }

                setSelectedOption(item)
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        <MenuDivider />
        {additionalItems}
      </MenuList>
    </Menu>
  )
}

export { CustomSelect }
