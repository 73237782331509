import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback } from "react"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete"
import type { SubmitHandler } from "react-hook-form"
import { useForm } from "react-hook-form"
import type { SingleValue } from "react-select"
import { mutate } from "swr"
import { z } from "zod"

import type { BankAccountWithBridgeData } from "@utopia/safe-ramp-utils"

import { theme as utopiaTheme } from "~/theme"

import { createBankAccount } from "../../api/bank-accounts"

const schema = z.object({
  fullLegalName: z.string().min(2, "Name must be at least 2 characters long"),
  nickname: z.string().optional(),
  addressLine1: z.string().optional(),
  bankName: z.string().min(1, "Bank name is required"),
  routingNumber: z
    .string()
    .min(1, "ACH routing number is required.")
    .regex(/^\d+$/, "Your ACH routing number should only include digits.")
    .length(9, "Your ACH routing number should have 9 digits."),
  accountNumber: z
    .string()
    .min(1, "Account number is required.")
    .regex(/^\d+$/, "Account number must only include numbers.")
    .max(17, "Your account number should only include digits."),
})

interface AddBankAccountModalContentProps {
  onClose: () => void
  onAddBankAccount: (created: BankAccountWithBridgeData) => void
}

type FormInputs = {
  fullLegalName: string
  nickname: string
  addressLine1: string
  addressLine2: string
  country: string
  city: string
  state: string
  postalCode: string
  bankName: string
  routingNumber: string
  accountNumber: string
}

const AddBankAccountModalContent = ({ onClose }: AddBankAccountModalContentProps) => {
  const toast = useToast()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: zodResolver(schema),
  })
  type AddressComponent = {
    long_name: string
    short_name: string
    types: string[]
  }

  const getAddressComponent = (
    addressComponents: AddressComponent[],
    type: string,
    attribute: keyof Omit<AddressComponent, "types"> = "long_name",
  ) => {
    const component = addressComponents.find((c) => c.types.includes(type))
    return component ? component[attribute] : ""
  }

  const handleAddressChange = useCallback(
    async (address: SingleValue<{ value: { place_id: string } }>) => {
      if (!address) {
        // Clear all autocompleted form inputs
        setValue("addressLine1", "")
        setValue("addressLine2", "")
        setValue("postalCode", "")
        setValue("city", "")
        setValue("state", "")
        return
      }

      void geocodeByPlaceId(address.value.place_id).then((results) => {
        const addressComponents = results[0].address_components
        if (addressComponents) {
          const addressLine1 = [
            getAddressComponent(addressComponents, "street_number"),
            getAddressComponent(addressComponents, "route"),
          ].join(" ")

          const addressLine2 = getAddressComponent(addressComponents, "subpremise")

          const city =
            getAddressComponent(addressComponents, "locality") ||
            getAddressComponent(addressComponents, "postal_town")
          const state = getAddressComponent(
            addressComponents,
            "administrative_area_level_1",
            "short_name",
          )
          const zip =
            getAddressComponent(addressComponents, "postal_code") +
            getAddressComponent(addressComponents, "postal_code_suffix")

          setValue("addressLine1", addressLine1, {
            shouldValidate: true,
          })
          setValue("addressLine2", addressLine2, {
            shouldValidate: true,
          })
          setValue("city", city, { shouldValidate: true })
          setValue("state", state, {
            shouldValidate: true,
          })
          setValue("postalCode", zip, { shouldValidate: true })
        }
      })
    },

    [setValue, getAddressComponent],
  )

  const countryOptions = [
    { value: "USA", label: "USA" },
    { value: "Other", label: "Other" },
  ]

  const handleAddBankAccount: SubmitHandler<FormInputs> = useCallback(
    async (data) => {
      const country = watch("country")
      const addressLine1 = watch("addressLine1")
      const addressLine2 = watch("addressLine2")
      const city = watch("city")
      const state = watch("state")
      const postalCode = watch("postalCode")

      if (country !== "USA" && country !== "Other") {
        toast({
          title: "Country is required",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
        return
      }

      if (country === "USA") {
        if (addressLine1 === "" || addressLine1 === undefined) {
          toast({
            title: "Address is required",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
          return
        }
      }

      try {
        const res = await mutate(
          "/bank-accounts",
          createBankAccount({
            displayName: data.nickname,
            input: {
              accountOwnerName: data.fullLegalName,
              bankName: data.bankName,
              routingNumber: data.routingNumber,
              accountNumber: data.accountNumber,
              ...(country === "USA" && {
                address: {
                  streetLine1: addressLine1,
                  streetLine2: addressLine2,
                  city: city,
                  state: state,
                  postalCode: postalCode,
                  country: "USA",
                },
              }),
            },
          }),
          {
            populateCache: (
              result,
              bankAccounts: BankAccountWithBridgeData[] | undefined,
            ) => (bankAccounts ?? []).concat(result),
          },
        )

        if (!res) {
          toast({
            title: "Failed to create bank account",
            description: "Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
          return
        }

        const accountLabel = `${res.name} (${res.bridgeExternalAccount.bankName} - ${res.bridgeExternalAccount.lastFourDigits})`

        toast({
          title: `Bank account "${accountLabel}" added`,
          status: "success",
          duration: 5000,
          isClosable: true,
        })
        onClose()
      } catch (e) {
        const error = e as { response?: { status: number } }
        if (error?.response?.status === 409) {
          toast({
            status: "error",
            title: "Duplicate Account.",
            description:
              "Please enter a new account number and correct routing number to create a new bank account. Contact Utopia if there any issues.",
          })
        } else {
          toast({
            title: "Failed to create bank account",
            description: "Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        }
      }
    },
    [onClose, toast, watch],
  )

  return (
    <VStack alignItems="start" pb={4}>
      <Divider />

      <form style={{ width: "100%" }} onSubmit={handleSubmit(handleAddBankAccount)}>
        <VStack gap="16px" alignItems="start" w="full">
          <Text color="stone.700" fontSize="lg" fontWeight="medium">
            Account owner details
          </Text>

          <Flex w="full" justifyContent="space-between">
            <VStack alignItems="start" width="50%" pr={2}>
              <FormControl isInvalid={Boolean(errors.fullLegalName)}>
                <FormLabel color="stone.700">Full legal name</FormLabel>
                <Input
                  {...register("fullLegalName")}
                  w="full"
                  variant="outline"
                  placeholder="Enter full legal name"
                />
                <FormErrorMessage>{errors.fullLegalName?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
            <VStack alignItems="start" width="50%" pl={2}>
              <FormControl isInvalid={Boolean(errors.nickname)}>
                <FormLabel color="stone.700">Nickname</FormLabel>
                <Input
                  {...register("nickname")}
                  variant="outline"
                  placeholder="Enter nickname"
                />
                <FormErrorMessage>{errors.nickname?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </Flex>
          {/* Row 2 */}
          <Flex w="full">
            <VStack alignItems="start" width="100%" pl={2}>
              <FormControl isInvalid={Boolean(errors.state)}>
                <FormLabel color="stone.700">Country</FormLabel>
                <Select
                  w="full"
                  {...register("country")}
                  color="stone.500"
                  variant="outline"
                  placeholder="Select country"
                >
                  {countryOptions.map((country) => (
                    <option value={country.value}>{country.label}</option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </Flex>

          {watch("country") === "USA" && (
            <>
              <Flex w="full" justifyContent="space-between">
                <VStack alignItems="start" w="100%" pr={2}>
                  <FormControl isInvalid={Boolean(errors.addressLine1)}>
                    <FormLabel color="stone.700">Address</FormLabel>
                    <GooglePlacesAutocomplete
                      apiKey={import.meta.env.VITE_GOOGLE_PLACES_API_KEY}
                      autocompletionRequest={{
                        componentRestrictions: { country: ["us"] },
                      }}
                      {...register("addressLine1", { required: true })}
                      selectProps={{
                        styles: {
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: "14px",
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({
                            ...base,
                            border: "1px outline",
                            boxShadow: "0",
                            borderColor: utopiaTheme.colors.stone[200],
                            "&:hover": {
                              color: utopiaTheme.colors.stone[200],
                            },
                            paddingLeft: "4px",
                            height: "36px",
                            cursor: "text",
                          }),
                        },
                        onChange: async (address) => {
                          await handleAddressChange(address)
                        },
                        isClearable: true,
                        noOptionsMessage: () => null,
                        placeholder: <Text px={1}>Enter address</Text>,
                        components: {
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        },
                      }}
                    />

                    <FormErrorMessage>{errors.addressLine1?.message}</FormErrorMessage>
                  </FormControl>
                </VStack>
              </Flex>
            </>
          )}

          {/* Bank account details */}
          <Divider />

          <Text color="stone.700" fontSize="lg" fontWeight="medium">
            Bank account details
          </Text>

          {/* Row 1 */}
          <Flex w="full" justifyContent="space-between">
            <VStack alignItems="start" w="full" pr={2}>
              <FormControl isInvalid={Boolean(errors.bankName)}>
                <FormLabel color="stone.700">Bank name</FormLabel>
                <Input
                  {...register("bankName", { required: true })}
                  w="full"
                  variant="outline"
                  placeholder="Enter bank name"
                />
                <FormErrorMessage>{errors.bankName?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </Flex>

          {/* Row 2 */}
          <Flex w="full" justifyContent="space-between">
            <VStack alignItems="start" width="50%" pr={2}>
              <FormControl isInvalid={Boolean(errors.accountNumber)}>
                <FormLabel color="stone.700">Account number</FormLabel>
                <Input
                  {...register("accountNumber", { required: true })}
                  w="full"
                  variant="outline"
                  placeholder="Enter account number"
                />
                <FormErrorMessage>{errors.accountNumber?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
            <VStack alignItems="start" width="50%" pl={2}>
              <FormControl isInvalid={Boolean(errors.routingNumber)}>
                <FormLabel color="stone.700">ACH Routing number</FormLabel>
                <Input
                  {...register("routingNumber", { required: true })}
                  variant="outline"
                  placeholder="Enter ACH routing number"
                />
                <FormErrorMessage>{errors.routingNumber?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </Flex>

          {/* Buttons */}

          <Divider />

          <Flex w="full" justifyContent="space-between">
            <Button onClick={onClose} width="50%" mr={2} variant="outline">
              Go back
            </Button>
            <Button type="submit" width="50%" ml={2} variant="solid">
              Add
            </Button>
          </Flex>
        </VStack>
      </form>
    </VStack>
  )
}

export { AddBankAccountModalContent }
