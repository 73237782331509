import { InfoIcon } from "@chakra-ui/icons"
import { Divider, HStack, Icon, Image, Text, Tooltip, VStack } from "@chakra-ui/react"
import { useMemo, useState } from "react"

import { generateTooltipMessage } from "@utopia/fees"

interface OfframpCheckoutProps {
  total: string
  utopiaTransactionFee: string
  receivedAmount: string
  token: "USDC" | "USDT"
}

const OfframpCheckout = ({
  total,
  utopiaTransactionFee,
  receivedAmount,
  token,
}: OfframpCheckoutProps) => {
  const [transactionFeeTooltipMessage, setTransactionFeeTooltipMessage] = useState("")

  useMemo(() => {
    const newTooltipMessage = generateTooltipMessage({
      type: "safeAppOffRamp",
      currency: token === "USDC" ? "usdc" : "usdt",
    })
    setTransactionFeeTooltipMessage(newTooltipMessage)
  }, [token])

  return (
    <VStack
      mt={4}
      mb={1}
      background="white"
      w="full"
      spacing={2}
      borderColor="stone.200"
      _dark={{ borderColor: "stone.700" }}
      borderWidth="1px"
      borderRadius="md"
      py={2}
    >
      <HStack w="full" justifyContent="space-between" px={4}>
        <Text color="stone.700" _dark={{ color: "stone.400 " }}>
          Bank transfer amount
        </Text>
        <HStack spacing={1}>
          <Text>{receivedAmount}</Text>
          <Image src="images/icons/usflag.svg" w={4} h={4} aria-hidden alt="USD" />
          <Text>USD</Text>
        </HStack>
      </HStack>
      <HStack w="full" justifyContent="space-between" px={4}>
        <VStack alignItems="start" w="250px">
          <HStack>
            <Text color="stone.700" _dark={{ color: "stone.400 " }}>
              {token === "USDC" ? "Transaction fee (0.50%)" : "Transaction fee (0.65%)"}
            </Text>

            <Tooltip hasArrow label={transactionFeeTooltipMessage}>
              <Icon as={InfoIcon} fontSize="sm" ml="0.5" />
            </Tooltip>
          </HStack>
        </VStack>
        <HStack spacing={1}>
          <Text>{utopiaTransactionFee}</Text>
          <Image
            src={`images/icons/${token.toLowerCase()}.svg`}
            w={4}
            h={4}
            aria-hidden
            alt={token.toUpperCase()}
          />
          <Text>{token.toUpperCase()}</Text>
        </HStack>
      </HStack>
      <Divider w="90%" />
      <HStack w="full" justifyContent="space-between" px={4}>
        <Text color="stone.700" _dark={{ color: "stone.400 " }}>
          Total
        </Text>
        <HStack spacing={1}>
          <Text>{total}</Text>
          <Image
            src={`images/icons/${token.toLowerCase()}.svg`}
            w={4}
            h={4}
            aria-hidden
            alt={token.toUpperCase()}
          />
          <Text>{token.toUpperCase()}</Text>
        </HStack>
      </HStack>
    </VStack>
  )
}

export { OfframpCheckout }
