import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"

export interface SafeModalProps {
  isShown: boolean
  onClose: () => void
  modalContent: JSX.Element
  headerText?: string
  modalWidth?: string
  isCloseable?: boolean
}

const SafeModal = ({
  isShown,
  onClose,
  modalContent,
  headerText,
  modalWidth = "628px",
  isCloseable = true,
}: SafeModalProps) => {
  return (
    <Modal isOpen={isShown} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth={modalWidth || "628px"}>
        <ModalHeader>{headerText}</ModalHeader>
        {isCloseable && <ModalCloseButton onClick={onClose} />}
        <ModalBody>{modalContent}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { SafeModal }
