import { Box, Button, Image, Link, Spacer, chakra } from "@chakra-ui/react"
import React from "react"

type HeaderProps = {
  children?: React.ReactNode
}

export const HeaderComponents = ({ children }: HeaderProps) => {
  return (
    <chakra.nav
      p={4}
      gap={4}
      display="flex"
      justifyContent="start"
      alignItems="center"
      gridAutoFlow="column"
      borderBottom="1px solid"
      _light={{
        bgColor: "white",
        borderBottomColor: "stone.200",
      }}
      _dark={{
        bgColor: "stone.900",
        borderBottomColor: "stone.700",
      }}
    >
      <Box display="flex" gap={4}>
        <Link href="https://utopialabs.com" isExternal>
          <Image src="/logo.svg" width="40px" height="40px" alt="Utopia logo" />
        </Link>
        {children}
      </Box>
      <Spacer />

      <Link href="https://app.utopialabs.com" isExternal>
        <Button variant="outline" colorScheme="earth">
          Go to application
        </Button>
      </Link>
    </chakra.nav>
  )
}
