import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  MenuItem,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"
import { BigNumber, ethers, utils } from "ethers"
import { useCallback, useMemo } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"

import {
  calculateAmountWithTotalFeeInCents,
  calculateTotalFeeInCents,
} from "@utopia/fees"
import type { Transfer } from "@utopia/safe-ramp-utils"
import {
  type BankAccountWithBridgeData,
  CENTS_DECIMALS,
  USDC_TOKEN_DECIMALS,
  USDT_TOKEN_DECIMALS,
} from "@utopia/safe-ramp-utils"
import { networkToUsdcAddress, networkToUsdtAddress } from "@utopia/utils-tokens"

import { isDev } from "~/lib/origin"

import ERC20_ABI from "../../abi/ERC20.json"
import { getBankAccounts } from "../../api/bank-accounts"
import { completeTransfer, createTransfer } from "../../api/transfer"
import { amplitude } from "../../packages/amplitude"
import { SafeModal } from "../modal/modal"
import { CustomSelect } from "../ui/select"
import { AddBankAccountModalContent } from "./add-bank-account"
import { CurrencySelector } from "./currency-selector"
import { OfframpCheckout } from "./offramp-checkout"

type BankAccountOption = BankAccountWithBridgeData & { label: string }
type CurrencyConversionOption = {
  symbol: string
  logo: string
  tokenAddress: string
}

const OfframpWidget = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [total, setTotal] = useState("")
  const [selectedBankAccount, setSelectedBankAccount] =
    useState<BankAccountOption | null>(null)
  const [amountTouched, setAmountTouched] = useState(false)

  const { data: bankAccounts } = useSWR<BankAccountWithBridgeData[]>(
    "/bank-accounts",
    getBankAccounts,
  )

  const bankAccountOptions: BankAccountOption[] = useMemo(
    () =>
      bankAccounts?.map((bankAccount) => ({
        ...bankAccount,
        label: `${bankAccount.name} (${bankAccount.bridgeExternalAccount.bankName} - ${bankAccount.bridgeExternalAccount.lastFourDigits})`,
      })) ?? [],
    [bankAccounts],
  )

  const { sdk, safe } = useSafeAppsSDK()
  // set the conversion options
  const network = safe.chainId
  const options = [
    {
      symbol: "USDC",
      logo: "images/icons/usdc.svg",
      tokenAddress: networkToUsdcAddress[network],
    },
    ...(network === 1
      ? [
          {
            symbol: "USDT",
            logo: "images/icons/usdt.svg",
            tokenAddress: networkToUsdtAddress[network],
          },
        ]
      : []),
  ]
  const token =
    options.find((t) => t.tokenAddress === networkToUsdcAddress[network]) || options[0]

  const [selectedConversion, setSelectedConversion] =
    useState<CurrencyConversionOption>(token)

  const {
    totalAmount,
    totalAmountInCents,
    amountInCents,
    receivedAmount,
    utopiaTransactionFee,
  } = useMemo(() => {
    const parsedAmount = parseFloat(total)
    if (isNaN(parsedAmount) || parsedAmount < 2) {
      return {
        totalAmount: 0,
        receivedAmount: 0,
        amountInCents: 0,
        utopiaTransactionFee: 0,
        totalAmountInCents: 0,
      }
    }
    const _amountInCents = Math.floor(parsedAmount * 100)
    const _totalAmountInCents = calculateAmountWithTotalFeeInCents({
      type: "safeAppOffRamp",
      amountInCents: _amountInCents,
      currency: selectedConversion?.symbol === "USDC" ? "usdc" : "usdt",
    })
    const _totalAmount = _totalAmountInCents / 100

    const _utopiaTransactionFee =
      calculateTotalFeeInCents({
        type: "safeAppOffRamp",
        amountInCents: _amountInCents,
        currency: selectedConversion?.symbol === "USDC" ? "usdc" : "usdt",
      }) / 100

    return {
      amountInCents: _amountInCents,
      receivedAmount: _amountInCents / 100,
      totalAmountInCents: _totalAmountInCents,
      totalAmount: _totalAmount,
      utopiaTransactionFee: _utopiaTransactionFee,
    }
  }, [total, selectedConversion?.symbol])

  const errorText = useMemo(() => {
    if (!isDev) {
      return amountTouched && amountInCents < 200
        ? `Amount must be greater than 2.00 ${selectedConversion?.symbol}`
        : ""
    }
    return amountTouched && amountInCents < 200
      ? `Note: Amount < 2.00 ${selectedConversion?.symbol} only for dev testing`
      : ""
  }, [amountInCents, amountTouched, selectedConversion?.symbol])

  const submitTx = useCallback(
    async (stableCoinAmount: number, bankAccount: BankAccountWithBridgeData) => {
      const contractAddress =
        selectedConversion?.symbol === "USDC"
          ? networkToUsdcAddress[safe.chainId]
          : networkToUsdtAddress[safe.chainId]
      const contract = new ethers.Contract(contractAddress, ERC20_ABI)

      let createdTransfer: Transfer = {} as Transfer
      let safeTxHash: string
      try {
        createdTransfer = await createTransfer({
          stableCoinAmount,
          bankAccountId: bankAccount.id,
          token: selectedConversion?.symbol === "USDC" ? "USDC" : "USDT",
        })

        if (!createdTransfer) {
          throw new Error("Failed to create transfer")
        }

        const amountToSend = ethers.utils.parseUnits(
          stableCoinAmount.toString(),
          selectedConversion?.symbol === "USDC"
            ? USDC_TOKEN_DECIMALS - CENTS_DECIMALS
            : USDT_TOKEN_DECIMALS - CENTS_DECIMALS,
        )

        const functionData = contract.interface.encodeFunctionData("transfer", [
          createdTransfer.bridgeTransfer.source_deposit_instructions.to_address,
          amountToSend,
        ])

        ;({ safeTxHash } = await sdk.txs.send({
          txs: [
            {
              to: contractAddress,
              value: "0",
              data: functionData,
            },
          ],
        }))
      } catch (e) {
        console.error(e)
        amplitude.track("offramp_fail", {
          amount: total,
          safe_address: safe.safeAddress,
          offramp_id: createdTransfer?.id,
        })

        return "error"
      }
      try {
        // attach Utopia data. It's ok if it fails
        await completeTransfer({
          safeTxHash,
          transferId: createdTransfer.id,
          token: selectedConversion?.symbol === "USDC" ? "USDC" : "USDT",
        })
      } catch (e) {
        console.warn(
          "Transfer data failed to save to Utopia. The created transaction is still valid.",
          e,
        )
      } finally {
        amplitude.track("offramp_success", {
          amount: total,
          safe_address: safe.safeAddress,
          offramp_id: createdTransfer.id,
        })
      }
      return "success"
    },
    [safe.chainId, safe.safeAddress, sdk.txs, total, selectedConversion],
  )

  const handleSubmitPayment = useCallback(async () => {
    const balances = await sdk.safe.experimental_getBalances()

    // get the current fiat equivelant of USDC/USDT from the array of balances
    const balanceItem = balances.items.find(
      (item) =>
        item.tokenInfo.address.toLowerCase() ===
        (selectedConversion?.symbol === "USDC"
          ? networkToUsdcAddress[safe.chainId].toLowerCase()
          : networkToUsdtAddress[safe.chainId]
        ).toLowerCase(),
    )

    if (!balanceItem) {
      toast({
        title: "Token not available",
        description: `You don't have any ${selectedConversion?.symbol} in your Safe.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      return
    }

    const balance = BigNumber.from(balanceItem.balance ?? "0")
    const balanceNumber = parseFloat(
      utils.formatUnits(
        balance,
        selectedConversion?.symbol === "USDC" ? USDC_TOKEN_DECIMALS : USDT_TOKEN_DECIMALS,
      ),
    )

    if (Number(balanceNumber) < totalAmount) {
      toast({
        title: "Insufficient balance",
        description: `Please add more ${selectedConversion?.symbol} to your Safe.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (!selectedBankAccount) {
      toast({
        title: "No bank account selected",
        description: "Please select or add a bank account to offramp to.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      return
    }

    if (amountInCents < 200) {
      toast({
        title: "Invalid amount",
        description: `Please enter a valid amount to offramp. Must be at least 2 ${selectedConversion?.symbol}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      return
    }

    void submitTx(totalAmountInCents, selectedBankAccount).then((res: string) => {
      if (res.includes("error")) {
        console.error(res)
        toast({
          title: "Error",
          description:
            "There was an error submitting your transaction. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        })

        return
      }

      toast({
        title: "Transfer created",
        description: "Please execute the transaction to complete the transfer.",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
      setTotal("0")
      navigate("/offramp/confirm")
    })
  }, [
    amountInCents,
    navigate,
    sdk.safe,
    selectedBankAccount,
    submitTx,
    toast,
    totalAmount,
    totalAmountInCents,
  ])

  const onAddBankAccount = useCallback((bankAccount: BankAccountWithBridgeData) => {
    setSelectedBankAccount(null)
  }, [])

  // const handleteDeleteBankAccount = async () => {
  //   deleteBankAccount(bankAccountInfo.id!).then((res) => {
  //     toast({
  //       title: "Bank account deleted",
  //       description: "Your bank account has successfully been deleted.",
  //       status: "success",
  //       duration: 5000,
  //       isClosable: true
  //     });

  //     setBankAccountInfo({
  //       id: "",
  //       bankName: "",
  //       last_4: ""
  //     });

  //     onDeleteClose();
  //   });
  // };

  return (
    <Box>
      <SafeModal
        modalWidth="660px"
        isShown={isOpen}
        onClose={onClose}
        headerText="Add a bank account"
        modalContent={
          <AddBankAccountModalContent
            onClose={onClose}
            onAddBankAccount={onAddBankAccount}
          />
        }
      />
      {/* <SafeModal
        isShown={isDeleteOpen}
        onClose={onDeleteClose}
        headerText="Confirm deletion of bank account"
        modalContent={
          <>
            <Text mb={4}>
              Are you sure you want to delete this bank account{" "}
              <Text
                fontWeight="bold"
                as="span"
              >{`${bankAccountInfo.bankName} - ${bankAccountInfo.last_4}`}</Text>
              ? You can add it again after deleting.
            </Text>

            <Flex mb={2} w="full" justifyContent="space-between">
              <Button
                onClick={onDeleteClose}
                width="50%"
                mr={2}
                variant="outline"
              >
                Go back
              </Button>
              <Button
                type="submit"
                width="50%"
                ml={2}
                variant="solid"
                background="red.500!"
                onClick={handleteDeleteBankAccount}
              >
                Delete
              </Button>
            </Flex>
          </>
        }
      /> */}

      <Text fontWeight={500}>Details</Text>

      <Box mt={4} w="100%">
        <FormLabel fontWeight="400" fontSize="sm">
          Recipient
        </FormLabel>
        <Flex alignItems="center" w="100%">
          <CustomSelect
            items={bankAccountOptions}
            selectedOption={selectedBankAccount}
            setSelectedOption={setSelectedBankAccount}
            additionalItems={
              <MenuItem key="add-bank-account" onClick={onOpen}>
                Add a bank account +
              </MenuItem>
            }
          />

          {/* {bankAccountInfo.bankName !== "" && (
            <Box
              backgroundColor="white"
              borderColor="stone.200"
              height={9}
              display="flex"
              _hover={{
                backgroundColor: "stone.50"
              }}
              justifyItems="center"
              alignItems="center"
              color="gray.500"
              borderWidth={1}
              rounded="md"
              cursor="pointer"
              px={3}
              ml={1}
              onClick={onDeleteOpen}
            >
              <DeleteIcon />
            </Box>
          )} */}
        </Flex>
      </Box>

      <Flex width="100%" mt={4}>
        <Box width="50%">
          <FormLabel fontWeight="400" fontSize="sm">
            Amount
          </FormLabel>
          <Input
            onBlur={() => {
              setAmountTouched(true)
            }}
            onChange={(e) => {
              setTotal(e.target.value)
            }}
            isInvalid={!isDev && !!errorText}
            errorBorderColor="red.300"
            type="number"
            borderRightRadius={0}
            variant="outlined"
            placeholder="Enter amount"
          />
          <Text fontSize="xs" color="red.500">
            {errorText}
          </Text>
        </Box>
        <Box width="50%">
          <FormLabel fontWeight="400" fontSize="sm">
            Currency
          </FormLabel>
          <CurrencySelector
            items={options}
            selectedOption={selectedConversion}
            setSelectedOption={setSelectedConversion}
          />
        </Box>
      </Flex>

      <Divider my={4} />

      <Text fontWeight={500}>Transaction cost</Text>

      <OfframpCheckout
        total={totalAmount.toLocaleString()}
        utopiaTransactionFee={utopiaTransactionFee.toLocaleString()}
        receivedAmount={receivedAmount.toLocaleString()}
        token={selectedConversion?.symbol === "USDC" ? "USDC" : "USDT"}
      />

      <Button onClick={handleSubmitPayment} mt={2} w="100%" colorScheme="earth">
        Submit
      </Button>
    </Box>
  )
}

export { OfframpWidget }
