import { Center, Image, Text, VStack } from "@chakra-ui/react"
import { DynamicWidget, useDynamicContext } from "@dynamic-labs/sdk-react-core"

export const App = () => {
  const { primaryWallet } = useDynamicContext()
  return (
    <Center h="100vh">
      <VStack>
        <Image src="/images/logo.png" mb="10" />
        {primaryWallet ? <Text>Loading bank transfer data...</Text> : <DynamicWidget />}
      </VStack>
    </Center>
  )
}
