import { Box, Button, Center, Text, VStack } from "@chakra-ui/react"
import { useSafeAppsSDK } from "@gnosis.pm/safe-apps-react-sdk"
import { useMemo } from "react"

import { Header } from "../../components/header/header"
import { TextLink } from "../../components/ui/text-link"
import { useAccount } from "../../hooks/use-account"
import { amplitude } from "../../packages/amplitude"

const Verify = () => {
  const { account } = useAccount()
  const { safe } = useSafeAppsSDK()
  const kycLink = useMemo(
    () => account?.offRampUser?.kybStatus?.bridgeKYBStatus.kycLink,
    [account?.offRampUser?.kybStatus?.bridgeKYBStatus.kycLink],
  )
  const kycStatus = useMemo(
    () => account?.offRampUser?.kybStatus?.bridgeKYBStatus.kycStatus,
    [account?.offRampUser?.kybStatus?.bridgeKYBStatus.kycStatus],
  )

  const verifyWithPersona = () => {
    amplitude.track("begin_persona", {
      safe_address: safe.safeAddress,
    })

    window.open(kycLink, "_blank")
  }

  return (
    <Box background="stone.50" height="100vh">
      <Header />
      <Center>
        <Box p="40px" w="628px" h="100%">
          <Box mb={8}>
            <Text mb={4} fontSize="3xl" lineHeight="32px" fontWeight={600}>
              You’ll need to finish these 2 steps to verify your business.{" "}
            </Text>
            <Text color="stone.500">
              Upon completion, your business will be verified within 2 days. If it is not,
              please{" "}
              <TextLink href="mailto:compliance@utopialabs.com" text="contact support." />
            </Text>
          </Box>

          <VStack spacing={8} alignItems="flex-start">
            <Box
              borderRadius="8px"
              borderWidth="1px"
              borderColor="stone.200"
              backgroundColor="white"
              p="16px"
            >
              <Text lineHeight="20px" mb={2} fontSize="lg" fontWeight={600}>
                First, verify your legal entity.
              </Text>
              <Text color="stone.500" lineHeight="20px">
                You can also complete this process through an email we just sent you.
              </Text>
              {kycStatus === "manual_review" ? (
                <Button mt={4} w="100%" colorScheme="earth">
                  ✓ Submitted
                </Button>
              ) : (
                <Button onClick={verifyWithPersona} mt={4} w="100%" colorScheme="earth">
                  Verify with Persona
                </Button>
              )}
            </Box>

            <Box
              borderRadius="8px"
              borderWidth="1px"
              borderColor="stone.200"
              backgroundColor="white"
              p="16px"
            >
              <Text mb={2} lineHeight="20px" fontSize="lg" fontWeight={600}>
                Then, verify your beneficiaries or authorized representatives.
              </Text>
              <Text color="stone.500" lineHeight="20px">
                When completing the first step, you’ll identify beneficiaries or
                authorized representatives, who will then receive an email from Persona to
                verify their identities.
              </Text>
            </Box>
          </VStack>
        </Box>
      </Center>
    </Box>
  )
}

export default Verify
