import { createContext } from "react"

import type { Account, User } from "@utopia/safe-ramp-utils"

export type AppState = {
  account?: Account
  user?: User
  isLoading: boolean
}

export const AppContext = createContext<AppState>({} as AppState)
