import type { AxiosResponse } from "axios"

import type { BankAccountInput, BankAccountWithBridgeData } from "@utopia/safe-ramp-utils"

import { axios } from "../clients/axios"

export const getBankAccounts = async (): Promise<BankAccountWithBridgeData[]> => {
  const response: AxiosResponse<BankAccountWithBridgeData[]> = await axios.get(
    `/api/bank-accounts`,
  )
  return response.data
}

export const createBankAccount = async (
  data: BankAccountInput,
): Promise<BankAccountWithBridgeData> => {
  const response: AxiosResponse<BankAccountWithBridgeData> = await axios.post(
    `/api/bank-accounts`,
    data,
  )
  return response.data
}
