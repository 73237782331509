import { Box, Center, Text } from "@chakra-ui/react"

import { Header } from "../components/header/header"
import { TextLink } from "../components/ui/text-link"

export default function KYBFailed() {
  return (
    <Box background="stone.50" height="100vh">
      <Header />
      <Center>
        <Box p="40px" w="628px" h="100%">
          <Box mb={8}>
            <Text mb={4} fontSize="3xl" lineHeight="32px" fontWeight={600}>
              Verification Unsuccessful
            </Text>
            <Text color="stone.500">
              We were unable to approve your organization for USDC/USDT bank transfers.
              Please contact{" "}
              <TextLink
                href="mailto:compliance@utopialabs.com"
                text="compliance@utopialabs.com"
              />{" "}
              for assistance.
            </Text>
          </Box>
        </Box>
      </Center>
    </Box>
  )
}
