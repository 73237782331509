import { ChakraProvider } from "@chakra-ui/react"
import SafeProvider from "@gnosis.pm/safe-apps-react-sdk"
import React, { memo, useMemo } from "react"
import { createRoot } from "react-dom/client"
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom"
import { SWRConfig } from "swr"

import { BridgeTermsOfServiceRedirect } from "./components/bridge/bridge-tos-redirect"
import { useHandleRoute } from "./hooks/use-handle-route"
import "./index.css"
import ErrorPage from "./pages/error-page"
import { InvalidNetworkPage } from "./pages/invalid-network"
import KYBFailed from "./pages/kyb-failed"
import { App } from "./pages/login"
import Offramp from "./pages/offramp/offramp"
import OfframpConfirm from "./pages/offramp/offramp-confirm"
import SafeApp from "./pages/onboarding"
import Verify from "./pages/verify/verify"
import { AppProvider } from "./providers/app-provider"
import { DynamicProvider } from "./providers/dynamic-provider"
import { theme } from "./theme"

const getRoutes = () =>
  createBrowserRouter([
    {
      path: "/",
      element: <AppWrapper />,
      errorElement: (
        <ChakraProvider theme={theme}>
          <ErrorPage />
        </ChakraProvider>
      ),
      children: [
        {
          path: "/login",
          element: <App />,
        },
        {
          path: "/onboarding",
          element: <SafeApp />,
        },
        {
          path: "/verify",
          element: <Verify />,
        },
        {
          path: "/offramp",
          element: <Offramp />,
        },
        {
          path: "/offramp/confirm",
          element: <OfframpConfirm />,
        },
        {
          path: "/kyb-failed",
          element: <KYBFailed />,
        },
      ],
    },
    {
      path: "/verify/tos-redirect",
      element: (
        <ChakraProvider theme={theme}>
          <BridgeTermsOfServiceRedirect />,
        </ChakraProvider>
      ),
    },
    {
      path: "/invalid-network",
      element: (
        <ChakraProvider theme={theme}>
          <InvalidNetworkPage />
        </ChakraProvider>
      ),
    },
  ])

const HandleRoute = memo(() => {
  useHandleRoute()
  return <></>
})

const AppWrapper = () => {
  const { pathname } = useLocation()
  if (pathname === "/") {
    return <Navigate to="/login" replace />
  }

  return (
    <ChakraProvider theme={theme}>
      <SafeProvider loader={<>Loading Safe...</>}>
        <SWRConfig>
          <DynamicProvider>
            <AppProvider>
              <HandleRoute />
              <Outlet />
            </AppProvider>
          </DynamicProvider>
        </SWRConfig>
      </SafeProvider>
    </ChakraProvider>
  )
}
const Index = () => {
  const router = useMemo(() => {
    return getRoutes()
  }, [])
  return <RouterProvider router={router} />
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
)
