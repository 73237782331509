import type { AxiosResponse } from "axios"

import type { KybStatus, OnboardInput } from "@utopia/safe-ramp-utils"

import { axios } from "../clients/axios"

export const onboardOrganization = async ({
  name,
  email,
}: OnboardInput): Promise<KybStatus> => {
  const response: AxiosResponse<KybStatus> = await axios.post(`/api/onboard`, {
    name,
    email,
  })
  return response.data
}
